import React, { Component } from 'react';
import '../../styles/GeneralStyles/footer.css';
import whiteLogo from '../../img/gls_logo_white.png';
import {Link} from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer className='footer'>
                <div className='footer-container'>
                    <Link to="https://www.glsauto.com">
                        <img src={whiteLogo} alt="gls-logo" className="logo-image-white" />
                    </Link>
                    <p className='footer-text'>© 2024 GLOBAL LENDING SERVICES / <a className="footer-link" href="http://www.nmlsconsumeraccess.org/" target="_blank" rel="noopener noreferrer">NMLS CONSUMER ACCESS</a> / NMLS ID: 1089726</p>
                </div>
            </footer>
        );
    }
}

export default Footer;
